import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <div
      style={{
        maxWidth: `300px`,
        marginBottom: `1.45rem`,
        margin: `0 auto`,
      }}
    >
      <Image />
    </div>
    <SEO title="Split Aces" />
    <p style={{
       marginTop: `10px`,
    }}>
      If you are looking for a company to provide you
      with IT and business support, you have come to
      the right place. We deliver exceptional support so
      that you can focus on running your business.
    </p>
  </Layout>
)

export default IndexPage
